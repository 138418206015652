import React from 'react';
import Slider from "react-slick";
import {HashLink} from 'react-router-hash-link';
import Img1 from '../../../assets/images/landing/img-1.png'
import Img2 from '../../../assets/images/landing/img-2.png'
import Img3 from '../../../assets/images/landing/img-3.png'
import Img4 from '../../../assets/images/landing/img-4.png'

const Advantages = () => {

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  };

  return (
    <div className="container-area-field advantages-fon" id="advantages">
      <div className="container-main-area big-container">
        <div className="advantages-area">
          <div className="title-home title-block top-line">
            <h2 className="title ">О нас</h2>
          </div>
          <div className="title-home title-block">
            <p className="title-sub">
              Нами разработана и произведена уникальная система автоматизации колокольного звона II-го поколения. В основу которой легли{" "}
              <span>НАДЕЖНОСТЬ</span> и <span>ФУНКЦИОНАЛЬНОСТЬ!</span>
            </p>
          </div>

          <div className="about-info">
            <p>
              Более 15 лет опыта в области автоматики и программирования
              позволили нашей команде, совместно с профессиональными звонарями,
              разработать функционального и доступного помощника. Данное
              оборудование повышает удобство совершения церковных таинств и
              богослужений. Настоятель (служитель) храма или монастыря в любой
              момент из любой точки мира может включить колокольный звон с
              помощью нашей системы. При этом сохраняется возможность ручного
              звона.
            </p>
          </div>

          <div className="title-home title-block">
            <div className="title-sub"></div>
          </div>
          <div className="slider slider-advantages">
            <Slider {...settings}>
              <div className="slider-card-area">
                <div className="card-image-block">
                  <img src={Img1} alt="" />
                </div>
                <div className="card-info-block">
                  <div className="card-title-block">
                    <div className="card-title">Надежный механизм</div>
                  </div>
                  <div className="card-info">
                    Наш механизм максимально прост, удобен и надежен в
                    использовании. Комплектующие возможно купить в ближайшем
                    магазине
                  </div>
                </div>
              </div>
              <div className="slider-card-area">
                <div className="card-image-block">
                  <img src={Img2} alt="" />
                </div>
                <div className="card-info-block">
                  <div className="card-title-block">
                    <div className="card-title">В помощь звонарю</div>
                  </div>
                  <div className="card-info">
                    Большинство подобных устройств исключают возможность живого
                    исполнения звона. Наша система разработана в помощь живому
                    звонарю
                  </div>
                </div>
              </div>
              <div className="slider-card-area">
                <div className="card-image-block">
                  <img src={Img3} alt="" />
                </div>
                <div className="card-info-block">
                  <div className="card-title-block">
                    <div className="card-title">Личный кабинет</div>
                  </div>
                  <div className="card-info">
                    Вместо использования громоздкого радиопульта мы предлагаем
                    управлять системей из Личного кабинета и Мобильного
                    приложения
                  </div>
                </div>
              </div>
              <div className="slider-card-area">
                <div className="card-image-block">
                  <img src={Img4} alt="" />
                </div>
                <div className="card-info-block">
                  <div className="card-title-block">
                    <div className="card-title">Оперативность</div>
                  </div>
                  <div className="card-info">
                    Установка системы в течение 3-х дней по территории всего СНГ
                  </div>
                </div>
              </div>
            </Slider>
          </div>
          <div className="button-area _center">
            <HashLink to="/#contact" smooth="true" className="btn btn-blue">
              Узнать больше
            </HashLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Advantages;
