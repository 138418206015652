import React, { Fragment } from 'react';
import Header from './components/header'
import Banner from './components/banner'
import About from './components/about'
import Advantages from './components/advantages'
import System from './components/system'
import Publications from './components/publications'
import Application from './components/application'
import Contact from "./components/contact"
import Footer from "./components/footer"
import ModalAgreement from "./modalAgreement"
import Prices from "./components/prices"
import MetaTags from 'react-meta-tags'

const Home = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>Золотой звон | Купить звонарь и куранты</title>
        <meta
          name="description"
          content="Электронный помощник звонарю. Собственное производство. Купить звонарь, куранты."
        />
        <meta
          property="og:title"
          content="Золотой звон | Купить звонарь и куранты"
        />
      </MetaTags>

      <Header />
      <main>
        <Banner />
        <Advantages />
        <Prices />
        {/* <About /> */}
        <System />
        <Publications />
        <Application />
        <Contact />
      </main>
      <Footer />
      <ModalAgreement />
    </Fragment>
  );
};

export default Home;
